<template>
    <div class="fps-upluad">
        <h1 class="section-title">
            FPS upload
        </h1>
        <div class="card">
            <div class="upluad-button">
                <input
                    id="upluad-cvs"
                    ref="inputFile"
                    type="file"
                    accept="application/vnd.ms-excel, .xlsx, .xls, .csv"
                    @change="uploadCvs"
                >
                <label for="upluad-cvs">Upload CSV</label>
            </div>
            <p class="info">
                Upload a CSV file. Make sure it has the Game, CPU, GPU, 1080 & 1440 columns.<br>
                Example: <a href="https://docs.google.com/spreadsheets/d/1lFuDNw6hcDD-AzytflO_61_-_KtGHQYg/edit#gid=1745601514" target="_blank">FPS CSV SAMPLE</a>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "FpsUpluad",
    methods: {
        async uploadCvs(event) {
            const csv = event.target.files[0];

            this.$store.commit("Application/SET_IS_LOADING", true);

            try {
                const formData = new FormData();
                formData.append("file", csv);

                const {
                    data: {
                        message: successMsg = "The CVS has been uploaded."
                    }
                } = await axios({
                    method: "POST",
                    url: "/fps-upload",
                    data: formData
                });

                this.$notify({
                    title: "Uploaded",
                    text: successMsg,
                    type: "success"
                });

                this.$refs.inputFile.value = null;
            } catch (e) {
                this.$notify({
                    text: e.response.data.errors.message,
                    type: "error"
                });
            } finally {
                this.$store.commit("Application/SET_IS_LOADING", false);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.fps-upluad {
    .info {
        margin: 0;
    }

    .upluad-button {
        input {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }

        input + label {
            font-size: 1.25em;
            font-weight: 700;
            color: white;
            background-color: var(--base-color);
            display: inline-block;
            cursor: pointer;
            border: 1px solid transparent;
            padding: .375rem .75rem;
            font-size: 1rem;
            line-height: 1.5;
            border-radius: .25rem;
        }
    }
}
</style>
